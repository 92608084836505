import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import { metaTitle } from '../utils/page-meta-utils'
import languages from '../i18n'
import errorPageBackground from 'assets/images/404.svg'

const NotFoundPage = () => {
  const intl = useIntl()
  const homeUrl = languages.find((language) => language.locale === intl.locale).home

  return (
    <Layout pageName='NotFoundPage'>
      <Helmet>
        <title>{ metaTitle(intl.formatMessage({ id: '404.meta_title' })) }</title>
      </Helmet>
      <Hero
        fullHeight
        title={ intl.formatMessage({ id: '404.title' }) }
        subtitle={ intl.formatMessage({ id: '404.subtitle' }) }
        ctaLabel={ intl.formatMessage({ id: '404.button_label' }) }
        ctaTo={ homeUrl }
        background={ errorPageBackground }
      />
    </Layout>
  )
}

export default NotFoundPage
